.RangeSlider {
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    .LabelNote {
        display: flex;
        justify-content: space-between;
        span {
            font-size: small;
            text-align: center; } } }
