.PlanningContainer {
    background-color: #fffce4;
    clear: both;
    padding-bottom: 20px; }
.BigImg {
    width: 60%;
    max-width: none;
    margin: 0 auto;
    margin-bottom: 20px;
    display: block; }
.LeftImg {
    display: block;
    float: left;
    margin-right: 20px; }
.LeftImg30Pct {
    display: block;
    float: left;
    width: 30% !important; }
.LeftImg60Pct {
    display: block;
    float: left;
    max-width: none !important;
    width: 60% !important; }
.LeftImg50Pct {
    display: block;
    float: left;
    max-width: none !important;
    width: 50% !important; }
.RightImg {
    display: block;
    float: right; }
.RightImg30Pct {
    display: block;
    float: right;
    width: 30% !important; }
.RightImg60Pct {
    display: block;
    float: right;
    max-width: none !important;
    width: 60% !important; }
.FullImg {
    width: 100% !important;
    display: block;
    margin: 0 auto !important;
    max-width: none !important; }
video.FullVideo {
    width: 100%; }
video.LeftVideo {
    width: 60%;
    float: left; }
