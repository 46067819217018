.MenuItem {
    margin: 5px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    a {
        color: #000;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        display: block;
        &.Active {
            font-weight: bold; }
        &:hover, &:active, &.active {
            font-weight: bold; } } }
