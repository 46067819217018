.DrawerToggle {
    width: 40px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
    div {
        width: 90%;
        height: 3px;
        background-color: white; } }
