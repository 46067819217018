.Backlink {
    position: absolute;
    left: -30px;
    top: 10px;
    cursor: pointer;
    transition: 300ms ease-in-out;
    transform: rotate(0) scale(1);
    &:hover {
        transform: rotate(-10deg) scale(1.2); }
    * {
        color: black; } }

.ExerciseBody {
    margin-bottom: 20px;
    min-height: 450px;
    .LoadedText {
        width: 100%; }
    h3 {
        margin-bottom: 20px;
        padding-bottom: 12px;
        border-bottom: 2px solid #fde70e !important; }
    img {
        width: 100%; }
    .BigFull {
        width: 100%;
        max-width: none;
        margin: 0 auto;
        margin-bottom: 20px; }
    .BigImg {
        width: 80%;
        max-width: none;
        margin: 0 auto;
        margin-bottom: 20px; }
    .LeftImg {
        float: left;
        width: 48%;
        margin-right: 2%; }
    .CaptionRightSide {
        float: left;
        width: 100%;
        margin-right: 2%;
        img {
            width: 48%; }
        figcaption {
            margin-top: 20px;
            width: 50%;
            float: left; } }
    .Right30Pct {
        width: 30%;
        margin-left: 2%; } }
