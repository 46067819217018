.Menu {
    margin: 30px 10px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    .NormalLink {
        margin: 5px 0;
        box-sizing: border-box;
        display: block;
        width: 100%;
        a {
            color: #000;
            text-decoration: none;
            width: 100%;
            box-sizing: border-box;
            display: block;
            &:hover {
                font-weight: bold;
                cursor: pointer; } } } }
