.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform .3s ease-out;
    &.Open {
        transform: translateX(0); }
    &.Close {
        transform: translateX(100%); }
    &.Extended {
        width: 80%; }
    .Logo {
        width: 100%; } }
