.Steps {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin: 30px 0 20px;
    position: relative;
    .Step {
        position: relative; }
    a {
        text-transform: capitalize;
        pointer-events: all;
        &:first-child {
            opacity: 1;
            pointer-events: all; }
        &.Disabled {
            opacity: .5;
            pointer-events: none; } }
    .StepsWarning {
        position: absolute;
        padding-top: 3px;
        background: rgba(0,0,0,0.7);
        color: #fff;
        text-align: center;
        font-size: 1.125rem;
        width: 100%;
        height: 100%; } }
