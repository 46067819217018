@import "~@fontsource/raleway/index.css";

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth; }
.introjs-tooltip {
  max-width: 600px; }
.introjs-tooltipbuttons {
  .introjs-button {
    color: #fff;
    box-shadow: none !important;
    background-color: #000;
    border-color: #000;
    outline: 0 !important;
    text-decoration: none;
    padding: 1rem 1rem;
    font-size: 1rem;
    line-height: 1.625;
    border-radius: 0;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    font-weight: 500;
    margin: .25rem !important;
    text-shadow: none !important;
    &.introjs-hidden {
      display: none; } } }

//skip intro deaktivieren
.introjs-skipbutton {
  display: none !important; }
// Possition intro js in middle
.introjs-left, .introjs-right {
  transform: translateY(-50%) !important;
  .introjs-arrow {
    top: 50% !important;
    bottom: auto; } }

// centers content equaly
.center {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center; }
.d-flex {
  align-items: center; }
.zi-middle {
  position: relative;
  z-index: 9; }
.text-button {
  width: 30%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 10px;
  margin-left: 20%;
  font-size: 30px;
  padding: 20px 40px;
  box-shadow: 2px 2px 6px rgba(0,0,0,.3);
  border: 3px solid #000000;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &:after {
    content: "?";
    border-radius: 50%;
    border: 2px solid #000000;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    padding: 4px 0 0 3px;
    font-weight: bold;
    background-color: #ffffff;
    box-shadow: 3px 2px 4px rgba(0,0,0,.3);
    transition: all 300ms ease-in-out; }
  &:hover {
    transform: scale(1.02);
    &:after {
      transform: scale(1.1) translateY(-10px); } }
  + img {
    margin: 0px !important; } }

.introjs-helperLayer {
  box-shadow: rgba(33,33,33, .8) 0px 0px 1px 2px, rgba(33,33,33,.7) 0px 0px 0px 5000px !important; }
// Intro JS
// .introjs-tooltip
//   background-color: transparent !important
//   padding: 0px !important
// .introjs-tooltipbuttons
//   padding: 0px !important
//   border: 0px !important
// .introjs-arrow
//   display: none !important

// Checkbox Switch
 *///* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  // width: 60px
  // height: 34px
  width: 42px;
  height: 20px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: #2196F3; }
    &:focus + .slider {
      box-shadow: 0 0 1px #2196F3; }
    &:checked + .slider:before {
      // -webkit-transform: translateX(26px)
      // -ms-transform: translateX(26px)
      // transform: translateX(26px)
      transform: translateX(20px); } }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      // height: 26px
      // width: 26px
      // left: 4px
      // bottom: 4px
      height: 18px;
      width: 18px;
      left: 2px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s; }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%; } } } }
.clear-both {
  clear: both; }

.editorToolbar {}
.editorWrapper {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1); }
.editorClass {
  padding: 0px 10px; }

.custom-range.moved::-webkit-slider-thumb {
  background: green; }
.custom-range.moved::-moz-range-thumb {
  background: green; }
.custom-range.moved::-ms-thumb {
  background: green; }

figcaption {
  font-size: 0.81em; }

.checkbox_container, .radio_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: #FDE70E;
      &:after {
        display: block; } } }
  &:hover input ~ .checkmark {
    background-color: #fffce4; } }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #f7f7f5;
  border: 2px solid #deded9;
  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #000000;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); } }

.radio_container .checkmark {
  border-radius: 50%;
  top: 0px; }
