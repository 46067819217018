.Exercises {}

.Extended {
    border: 1px solid #000;
    margin-bottom: 100px; }

.ButtonContainer {
    width: 20%; }
.Success {
    background: #e7ffde;
    padding: 20px; }
.NoSuccess {
    padding: 20px; }
.LetterButton {
    margin: 0px 20px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: #ededed;
    border: 1px solid #ccc;
    cursor: pointer;
    &:hover, &.Active {
        background: #CECECE;
        -webkit-box-shadow: inset 0px 0px 5px #B7B7B7;
        -moz-box-shadow: inset 0px 0px 5px #B7B7B7;
        box-shadow: inset 0px 0px 5px #B7B7B7;
        outline: none; }
    &.Active {
        background: #CECECE;
        -webkit-box-shadow: inset 0px 0px 5px #B7B7B7;
        -moz-box-shadow: inset 0px 0px 5px #B7B7B7;
        box-shadow: inset 0px 0px 5px #B7B7B7;
        outline: none; } }
