.SaveButton {
    font-size: 40px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    &:hover {
        transform: translateY(-10px); }
    &.Saving {
        color: green;
        animation: save;
        animation-name: save;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: reverse; } }
