
.RowExercise {
    margin-top: 20px;
    border-bottom: 5px solid #ccc;
    border-top: 2px solid #ccc; }
.Exercise {
    padding: 20px; }

.LetterButton {
    margin: 0px 20px;
    border-radius: 20px;
    background: #ededed;
    border: 1px solid #ccc;
    cursor: pointer;
    &:hover, &.Active {
        background: #CECECE;
        -webkit-box-shadow: inset 0px 0px 5px #B7B7B7;
        -moz-box-shadow: inset 0px 0px 5px #B7B7B7;
        box-shadow: inset 0px 0px 5px #B7B7B7;
        outline: none; }
    &.Active {
        background: #CECECE;
        -webkit-box-shadow: inset 0px 0px 5px #B7B7B7;
        -moz-box-shadow: inset 0px 0px 5px #B7B7B7;
        box-shadow: inset 0px 0px 5px #B7B7B7;
        outline: none; }
    &.Success {
        background: rgba(green, .3); }
    &.Wrong {
        background: rgba(red, .3); } }
