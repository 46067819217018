.Welcome {
    display: flex;
    align-items: center;
    margin: 0; }
.Header {
    background-color: #FDE70E;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    .Navigaton {} }
