.SliderWrapper {
    width: 100%;
    position: relative;
    height: 600px;
    overflow: hidden;
    transition: all 600ms ease-in-out;
    .Nr {
        font-family: monospace;
        font-size: 18px;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 60px;
        text-align: center;
        padding-top: 2px;
        height: 30px;
        box-sizing: border-box;
        border: 2px solid black;
        line-height: 20px; }
    .Control {
        position: absolute;
        height: 100%;
        width: 100%;
        a {
            cursor: pointer;
            position: absolute;
            width: 10%;
            top: 50%;
            transform: translateY(-50%);
            text-decoration: none;
            font-size: 60px;
            transition: all 300ms ease-in-out;
            &.Prev {
                left: 0px;
                &:hover {
                    transform: translate(-5%,-50%); } }
            &.Next {
                text-align: right;
                right: 2.5%;
                &:hover {
                    transform: translate(5%,-50%); } } } }


    .Slides {
        width: 80%;
        left: 10%;
        position: relative;
        img {
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            transition: all 600ms ease-in-out;
            transform: translateX(-200%);
            &.Active {
                transform: translateX(0); } } } }
