.ExerciseOverview {
    .InformationsBox {}

    .ExerciseBox {
        cursor: pointer;
        width: 20%;
        box-sizing: border-box;
        transform: scale(.8);
        transition: all 300ms ease-in-out;
        span {
            bottom: 7px;
            position: absolute;
            right: 15px;
            margin-left: 20px;
            line-height: 20px; }
        &:hover {
            transform: scale(.85); }
        &.ExerciseDisabled {
            //cursor: not-allowed
            //opacity: 0.6
            h4 {
                color: #666; } }
        &.ExerciseActive {
            background-color: rgba(#FDE70E,0.3) !important; }
        &.ExerciseCompleted {
            span {
                color: green;
                font-weight: bold; } }

        .ImageContainer {
            box-sizing: border-box;
            overflow: hidden;
            height: 170px;
            margin-bottom: 20px;
            img {
                width: 100%; } } }
    .allFinishedMessage {
        max-width: 50%;
        margin: 0 auto;
        margin-bottom: 20px;
        background-color: #f1f1f1;
        padding: 20px; }
    .NextExercise {
        color: orange;
        font-weight: bold; } }

