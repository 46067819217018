.HeadRow {
    height: 30px;
    opacity: 0;
    transition: all 300ms ease-in;
    &.Show {
        opacity: 1; } }
.InputStatus {}
.InputEmpty {
    background: salmon; }
.Row {
    width: 100%;
    margin: 0px !important;
    padding: 10px; }
.VerticalScroll {
    flex-wrap: nowrap;
    scroll-behavior: smooth; }
.Col {
    min-height: 250px;
    position: relative;
    //background-color: #f1f1f1
    padding-top: 20px;
    padding-bottom: 45px; }
.FadeInit {
    transform: scale(0);
    transition: all 500ms ease-out; }
.FadeOut {
    transition: all 500ms ease-out;
    transform: scale(0);
    opacity: 0; }
.FadeIn {
    transition: all 500ms ease-out;
    opacity: 1;
    transform: scale(1); }

.PlanVarGroup {
    margin-bottom: 10px; }

.TubeCol {
    position: relative;
    padding-bottom: 40px;
    &::after {
        content: '';
        position: absolute;
        //bottom: 25px
        height: 40px;
        left: 15px;
        right: 15px;
        border: 2px solid #deded9;
        background: #f7f7f5;
        border-radius: 0 0px 50% 50%;
        border-radius: 0px 0px calc(50% + 40px) calc(50% + 40px); }
    .PlanVarGroup {
        &:nth-child(2) {
            position: relative;
            &::before {
                opacity: 0.4;
                content: "";
                width: 80%;
                height: 80%;
                position: absolute;
                top: 10%;
                left: 10%;
                //background-color: red
                pointer-events: none;
                background-image: url("/assets/icons/tube.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center; } }
        &:nth-child(3) {
            position: relative;
            &::before {
                opacity: 0.4;
                content: "";
                width: 80%;
                height: 80%;
                position: absolute;
                pointer-events: none;
                top: 10%;
                left: 10%;
                //background-color: red
                background-image: url("/assets/icons/time.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center; } } } }
.MoreTubes {
    &::before {
        content: "";
        height: 100%;
        width: 15px;
        left: 0px;
        position: absolute;
        z-index: 9;
        //background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
        background: linear-gradient(to right, #fffce4 0%, rgba(255,255,255,0) 100%); }
    &::after {
        content: "";
        height: 100%;
        width: 15px;
        right: 0px;
        position: absolute;
        z-index: 9;
        //background: linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
        background: linear-gradient(to left, #fffce4 0%, rgba(255,255,255,0) 100%); } }


.Tooltipp {
    min-width: 80%;
    max-width: none;
    width: 600px; }
.Highlight {
    width: auto; }
.HelpManagerIcon {
    width: 105px !important;
    border-radius: 0 !important;
    box-shadow: 1px 1px 3px 1px rgba(0,0,0,.2) !important;
    position: absolute;
    right: 100px;
    top: -150px;
    z-index: 9;
    cursor: pointer; }

.DeleteButton {
    position: relative;
    z-index: 9;
    font-size: 25px;
    cursor: pointer;
    &:hover {
        color: red; } }
.RemoveTubeWrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9; }
.DeleteTubeButton {
    left: 50%;
    top: 7px;
    position: relative;
    transform: translateX(-50%); }
.TubeIndex {
    display: block;
    z-index: 9;
    font-size: 17px;
    border: 2px solid black;
    min-width: 20px;
    height: 20px;
    font-weight: 400;
    font-family: sans-serif;
    line-height: 15px;
    padding: 1px 0 0 3px;
    color: black;
    position: absolute;
    top: 5px;
    right: 20px;
    background-color: #e9ecef;
    //right: 20px
    //bottom: 2px
 }    //right: 16px

.AddVars, .AddTubes {
    font-size: 50px;
    color: black;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 300ms ease-out;
    z-index: 9;
    &:after {
        position: absolute;
        left: -100%;
        width: 300%;
        font-size: 15px;
        text-align: center;
        bottom: -10px;
        content: "Füge Variablen hinzu";
        line-height: 1.2em; }
    &:hover {
        color: green; }
    &.ButtonClicked {
        transform: translate(0, 0) scale(1);
        animation-play-state: paused;
        font-size: 35px;
        top: 23px;
        left: 30px;
        &:after {
            display: none !important; } } }
.AddTubes {
    &.ButtonClicked {
        top: 23px;
        left: 94%; }

    &:after {
        bottom: -25px;
        content: "Füge Reagengläser hinzu"; } }

.PlanInfoButton {
    width: 150px;
    height: 100px;
    background-color: #ffffff;
    background-image: url("/assets/icons/plan_info_button.png");
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.2) rotate(10deg); } }
.SaveButton, .DownloadButton {
    font-size: 50px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    position: relative;
    align-self: flex-end;
    &.Saving {
        color: green;
        animation: save;
        animation-name: save;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: reverse; }
    &:hover {
        transform: translateY(-10px); } }

@keyframes pulse {
    0% {
        transform: scale(1.1) translate(-50%, -50%); }
    100% {
        transform: scale(1) translate(-50%, -50%); } }

@keyframes save {
    0% {
        transform: scale(1) rotate(0); }
    20% {
        transform: scale(0.7) rotate(-180deg); }
    60% {
        transform: scale(1) rotate(-360deg); }
    80% {
        transform: scale(0.7) rotate(-540deg); }
    100% {
        transform: scale(1) rotate(-720deg); } }


