.LetterButton {
    margin: 0px 20px;
    border-radius: 20px;
    background: #ededed;
    border: 1px solid #ccc;
    cursor: pointer;
    &:hover, &.Active {
        background: #CECECE;
        -webkit-box-shadow: inset 0px 0px 5px #B7B7B7;
        -moz-box-shadow: inset 0px 0px 5px #B7B7B7;
        box-shadow: inset 0px 0px 5px #B7B7B7;
        outline: none; }
    &.Active {
        background: #CECECE;
        -webkit-box-shadow: inset 0px 0px 5px #B7B7B7;
        -moz-box-shadow: inset 0px 0px 5px #B7B7B7;
        box-shadow: inset 0px 0px 5px #B7B7B7;
        outline: none; }
    &.Success {
        background: rgba(green, .3); }
    &.Wrong {
        background: rgba(red, .3); } }

.HelpSessionRow {
    border-bottom: 1px solid #ccc;
    padding: 20px;
    border-right: 1px solid #ccc;
    position: relative;
    &.Active {
        background: #f1f1f1;
        &::after {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            content: ">";
            font-weight: 100;
            font-size: 400%; } } }
