
.QuestionRow {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;
    .QuestionText {
        font-weight: bold; }
    .QuestionAnswers {
        display: flex;
        justify-content: space-between; } }
