.User {
    padding: 20px;
    border: 1px solid #ccc; }
.Extended {
    border: 1px solid #000;
    .Bold {
        font-weight: bold; } }

.ButtonContainer {
    width: 20%; }
.LetterButton {
    margin: 0px 20px;
    border-radius: 20px;
    // width: 50px
    // height: 50px
    background: #ededed;
    border: 1px solid #ccc;
    cursor: pointer;
    &:hover, &.Active {
        background: #CECECE;
        -webkit-box-shadow: inset 0px 0px 5px #B7B7B7;
        -moz-box-shadow: inset 0px 0px 5px #B7B7B7;
        box-shadow: inset 0px 0px 5px #B7B7B7;
        outline: none; }
    &.Active {
        background: #CECECE;
        -webkit-box-shadow: inset 0px 0px 5px #B7B7B7;
        -moz-box-shadow: inset 0px 0px 5px #B7B7B7;
        box-shadow: inset 0px 0px 5px #B7B7B7;
        outline: none; } }
