.Auth {
    button {
        outline: 0 ! important;
        &:disabled {
            cursor: not-allowed;
            &:hover {} }
        &:not(:disabled) {
            animation: enable 0.3s linear; } } }


@keyframes enable {
    0% {
        transform: scale(1); }
    60% {
        transform: scale(1.1); }
    100% {
        transform: scale(1); } }
