.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    border-radius: 5px;
    max-height: 100vh;
    overflow-y: scroll; }

.Borderless {
    border-radius: 0px;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    a {
        color: #000 !important;
        background-color: #fde70e !important;
        border-color: #fde70e !important;
        &:hover {
            color: #fff !important;
            background-color: #000 !important;
            border-color: #000 !important; } }
    .row {
        margin: 0px !important; }
    img {
        max-width: none !important;
        width: 100% !important; } }
