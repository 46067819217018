.LetterButton, .DownloadButton {
    margin: 0px 20px;
    border-radius: 20px;
    background: #ededed;
    border: 1px solid #ccc;
    cursor: pointer;
    &:hover, &.Active {
        background: #CECECE;
        -webkit-box-shadow: inset 0px 0px 5px #B7B7B7;
        -moz-box-shadow: inset 0px 0px 5px #B7B7B7;
        box-shadow: inset 0px 0px 5px #B7B7B7;
        outline: none; }
    &.Active {
        background: #CECECE;
        -webkit-box-shadow: inset 0px 0px 5px #B7B7B7;
        -moz-box-shadow: inset 0px 0px 5px #B7B7B7;
        box-shadow: inset 0px 0px 5px #B7B7B7;
        outline: none; }
    &.Success {
        background: rgba(green, .3); }
    &.Wrong {
        background: rgba(red, .3); } }
.DownloadButton {
    padding: 5px 10px;
    margin: 20px;
    display: inline-block;
    &:hover {
        text-decoration: none; } }
.HelpSessionRow {
    border-bottom: 1px solid #ccc;
    padding: 20px; }
