.Backlink {
    display: none;
    position: absolute;
    left: -30px;
    top: 10px;
    cursor: pointer;
    transition: 300ms ease-in-out;
    transform: rotate(0) scale(1);
    &:hover {
        transform: rotate(-10deg) scale(1.2); }
    * {
        color: black; } }

.ExerciseBody {
    margin-bottom: 20px;
    min-height: 450px;
    position: relative;
    border-top: 3px solid #FDE70E;
    padding-top: 20px;
    .LoadedText {
        width: 100%; }
    h3 {
        text-transform: normal;
        margin-bottom: 20px;
        padding-bottom: 12px;
        border-bottom: 2px solid #fde70e !important; }
    img {
        width: 40%;
        max-width: 380px;
        margin-bottom: 20px;
        &.BigImg {
            width: 60%;
            max-width: none;
            margin: 0 auto;
            margin-bottom: 20px;
            display: block; }
        &.LeftImg {
            margin-right: 50px;
            display: block;
            float: left; }
        &.RightImg {
            display: block;
            float: right;
            margin-left: 50px; }
        &.FullImg {
            width: 100% !important;
            display: block;
            margin: 0 auto !important;
            max-width: none !important; } }
    figcaption {
        font-size: 0.8em; } }

.MailButton {
    font-size: 40px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: translateY(-10px); }
    &.Saving {
        color: green;
        animation: save;
        animation-name: save;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: reverse; } }

.SuccessMsg, .FailMsg {
    margin-top: 40px;
    font-size: 50px;
    line-height: 60px;
    img {
        display: block;
        margin: 0 auto; } }

@keyframes save {
    0% {
        transform: scale(1) rotate(0); }
    20% {
        transform: scale(0.7) rotate(-180deg); }
    60% {
        transform: scale(1) rotate(-360deg); }
    80% {
        transform: scale(0.7) rotate(-540deg); }
    100% {
        transform: scale(1) rotate(-720deg); } }

