.Conditions {
    display: flex;
    background-color: #fffce4;
    padding: 20px;
    div:first-child {
        padding-right: 20px; }
    div {
        display: flex;
        flex-direction: column; } }
.Wrong {
    background-color: red !important;
    position: relative;
    &:after, &:before {
        content: '';
        position: absolute;
        display: inline-block;
        transform: rotate(45deg);
        height: 100%;
        width: 12px;
        top: 3px;
        right: -30px;
        border-left: 5px solid red; }
    &:before {
         transform: rotate(-45deg);
         border-left: 0;
         right: -25px;
         border-right: 5px solid red; }
    &:hover {
        color: #ffffff !important;
        border-color: black !important; } }
.Right {
    color: #ffffff;
    background-color: green !important;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        display: inline-block;
        transform: rotate(45deg);
        height: 100%;
        width: 12px;
        bottom: 2px;
        right: -28px;
        border-bottom: 5px solid green;
        border-right: 5px solid green; }
    &:hover {
        border-color: black !important;
        color: #ffffff !important; } }


