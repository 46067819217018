.Input {
    margin-bottom: 20px;
    .Label {}
    .InputElement {
        &:focus {}
        &.Invalid {
            border-color: #ffcccb;
            background-color: #ffcccb; } } }
.Check {}

.CharacterCounter {
    float: right; }
.Valid {
    color: green; }
.Invalid {
    color: red; }

.Textarea {
    min-height: 200px;
    max-height: 300px; }



