.Overlay {
    position: fixed;
    z-index: 99999999999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    &.Success {
        background-color: rgba(102, 204, 153, 0.8); } }
.Check {
    top: 50%;
    left: 50%;
    position: absolute;
    margin: -30px 0 0 -30px;
    stroke-dasharray: 140;
    stroke-dashoffset: 140;
    animation: fill .5s linear normal;
    animation-fill-mode: forwards; }
@keyframes fill {
    from {
        stroke-dashoffset: 140; }
    to {
        stroke-dashoffset: 0; } }

.FullPageLoader {
    position: fixed;
    width: 34px;
    height: 34px;
    top: 50%;
    left: 50%;
    margin-left: -17px;
    margin-top: -17px;
    &:after, &:before {
        content: '';
        position: fixed;
        border: 2px solid white;
        width: 30px;
        height: 30px; }
    &:after {
        animation: spinner 2.5s linear infinite; }
    &:before {
        width: 44px;
        height: 44px;
        margin-left: -7px;
        margin-top: -7px;
        animation: spinner 2.5s linear infinite;
        animation-direction: reverse; } }
@keyframes spinner {
    from {
        transform: rotate(0deg); }
    to {
        transform: rotate(360deg); } }

