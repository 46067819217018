.HelpManager {
    border: 2px solid #000;
    padding: 20px;
    margin-bottom: 30px;
    position: relative; }
.BigImg {
    width: 60% !important;
    display: block;
    margin: 0 auto !important;
    max-width: none !important; }
.FullImg {
    width: 100% !important;
    display: block;
    margin: 0 auto !important;
    max-width: none !important; }
.HelpGuideButton {
    position: absolute;
    z-index: 9;
    right: -50px;
    cursor: pointer;
    bottom: -20px;
    width: 20%;
    img {
        width: 100%; } }
.HiddenText {}
